.service-container {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.service-container .icon {
  margin-top: 5%;
  font-size: 38px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.service-container .number-icon .icon-2 {
  height: 70px;
  width: 70px;
  line-height: 80px;
  border-radius: 64% 36% 55% 45% / 76% 72% 28% 24% !important;
  border-color: #f89d36 !important;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.service-container .number-icon .icon-2 i {
  font-size: 30px;
}

.service-container .number-icon .number {
  position: absolute;
  top: 0;
  right: 70px;
  left: 0;
  height: 35px;
  width: 35px;
  margin: 0 auto;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.service-container .number-icon .number span {
  line-height: 30px;
}

.service-container .content .number {
  font-size: 40px;
  color: #dee2e6;
}

.service-container .content .title {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.service-container.hover-bg {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.service-container.hover-bg .smooth-icon {
  position: absolute;
  bottom: -40px;
  right: -20px;
  font-size: 60px;
  color: #f8f9fa;
  -webkit-transition: all 0.8s ease;
  transition: all 0.8s ease;
}

.service-container.hover-bg:hover {
  background-color: #f89d36;
}

.service-container.hover-bg:hover .content .title {
  color: #ffffff !important;
}

.service-container.hover-bg:hover .content .serv-pera {
  color: #fafafb !important;
}

.service-container.hover-bg:hover .smooth-icon {
  font-size: 100px;
  opacity: 0.2;
  bottom: -20px;
  right: 10px;
}

.service-container:hover {
  background-color: #ffffff;
  -webkit-box-shadow: 0 10px 25px rgba(47, 60, 78, 0.15) !important;
          box-shadow: 0 10px 25px rgba(47, 60, 78, 0.15) !important;
}

.service-container:hover .icon {
  color: #f89d36;
  -webkit-animation: mover 1s infinite alternate;
          animation: mover 1s infinite alternate;
}

.service-container:hover .number-icon .icon-2 {
  background-color: #f89d36;
  border-radius: 50% !important;
}

.service-container:hover .number-icon .icon-2 i {
  color: #ffffff !important;
}

.service-container:hover .number-icon .number {
  color: #ffffff;
  background: #f89d36 !important;
  border-color: #ffffff !important;
}

.service-container:hover .content .title {
  color: #f89d36;
}

.service-container a:hover,
.service-container a .title:hover {
  color: #f89d36 !important;
}

@-webkit-keyframes mover {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
  }
}

@keyframes mover {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
  }
}

.service-wrapper {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.service-wrapper .icon {
  width: 60px;
  height: 60px;
  line-height: 45px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.service-wrapper .content .title {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  font-weight: 500;
}

.service-wrapper .big-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0.05;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.service-wrapper:hover {
  -webkit-box-shadow: 0 10px 25px rgba(47, 60, 78, 0.15) !important;
          box-shadow: 0 10px 25px rgba(47, 60, 78, 0.15) !important;
  background: #ffffff;
  -webkit-transform: translateY(-8px);
          transform: translateY(-8px);
  border-color: transparent !important;
}

.service-wrapper:hover .icon {
  background: #0062ff !important;
  color: #ffffff !important;
}

.service-wrapper:hover .big-icon {
  z-index: -1;
  opacity: 0.1;
  font-size: 160px;
}

.text-custom {
    color: #0062ff !important;
}

.uim-svg {
    display: inline-block;
    height: 1em;
    vertical-align: -0.125em;
    font-size: inherit;
    fill: var(--uim-color, currentColor);
}

.section_padding_130 {
  padding-top: 130px;
  padding-bottom: 130px;
}

.faq_area {
  position: relative;
  z-index: 1;
}

.faq-accordian {
  position: relative;
  z-index: 1;
}

.faq-accordian .card {
  position: relative;
  z-index: 1;
  margin-bottom: 1.5rem;
}

.faq-accordian .card:last-child {
  margin-bottom: 0;
}

.faq-accordian .card .card-header {
  background-color: #ffffff;
  padding: 0;
  border-bottom-color: #ebebeb;
}

.faq-accordian .card .card-header h6 {
  cursor: pointer;
  padding: 1.75rem 2rem;
  color: #3f43fd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.faq-accordian .card .card-header h6 span {
  font-size: 1.5rem;
}

.faq-accordian .card .card-header h6.collapsed {
  color: #070a57;
}

.faq-accordian .card .card-header h6.collapsed span {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.faq-accordian .card .card-body {
  padding: 1.75rem 2rem;
}

.faq-accordian .card .card-body p:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 575px) {
  .support-button p {
      font-size: 14px;
  }
}

.support-button i {
  color: #3f43fd;
  font-size: 1.25rem;
}

@media only screen and (max-width: 575px) {
  .support-button i {
      font-size: 1rem;
  }
}

.support-button a {
  text-transform: capitalize;
  color: #2ecc71;
}

@media only screen and (max-width: 575px) {
  .support-button a {
      font-size: 13px;
  }
}




.white-bg {
  background-color: #ffffff;
}
.page-heading {
  border-top: 0;
  padding: 0 10px 20px 10px;
}

.forum-post-container .media {
margin: 10px 10px 10px 10px;
padding: 20px 10px 20px 10px;
border-bottom: 1px solid #f1f1f1;
}
.forum-avatar {
float: left;
margin-right: 20px;
text-align: center;
width: 110px;
}
.forum-avatar .img-circle {
height: 48px;
width: 48px;
}
.author-info {
color: #676a6c;
font-size: 11px;
margin-top: 5px;
text-align: center;
}
.forum-post-info {
padding: 9px 12px 6px 12px;
background: #f9f9f9;
border: 1px solid #f1f1f1;
}
.media-body > .media {
background: #f9f9f9;
border-radius: 3px;
border: 1px solid #f1f1f1;
}
.forum-post-container .media-body .photos {
margin: 10px 0;
}
.forum-photo {
max-width: 140px;
border-radius: 3px;
}
.media-body > .media .forum-avatar {
width: 70px;
margin-right: 10px;
}
.media-body > .media .forum-avatar .img-circle {
height: 38px;
width: 38px;
}
.mid-icon {
font-size: 66px;
}
.forum-item {
margin: 10px 0;
padding: 10px 0 20px;
border-bottom: 1px solid #f1f1f1;
}
.views-number {
font-size: 24px;
line-height: 18px;
font-weight: 400;
}
.forum-container,
.forum-post-container {
padding: 30px !important;
}
.forum-item small {
color: #999;
}
.forum-item .forum-sub-title {
color: #999;
margin-left: 50px;
}
.forum-title {
margin: 15px 0 15px 0;
}
.forum-info {
text-align: center;
}
.forum-desc {
color: #999;
}
.forum-icon {
float: left;
width: 30px;
margin-right: 20px;
text-align: center;
}
a.forum-item-title {
color: inherit;
display: block;
font-size: 18px;
font-weight: 600;
}
a.forum-item-title:hover {
color: inherit;
}
.forum-icon .fa {
font-size: 30px;
margin-top: 8px;
color: #9b9b9b;
}
.forum-item.active .fa {
color: #1ab394;
}
.forum-item.active a.forum-item-title {
color: #1ab394;
}
@media (max-width: 992px) {
.forum-info {
  margin: 15px 0 10px 0;
  /* Comment this is you want to show forum info in small devices */
  display: none;
}
.forum-desc {
  float: none !important;
}
}

.ibox {
  clear: both;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0;
}
.ibox.collapsed .ibox-content {
  display: none;
}
.ibox.collapsed .fa.fa-chevron-up:before {
  content: "\f078";
}
.ibox.collapsed .fa.fa-chevron-down:before {
  content: "\f077";
}
.ibox:after,
.ibox:before {
  display: table;
}
.ibox-title {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background-color: #ffffff;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 3px 0 0;
  color: inherit;
  margin-bottom: 0;
  padding: 14px 15px 7px;
  min-height: 48px;
}
.ibox-content {
  background-color: #f1f1f1;
  color: inherit;
  padding: 15px 20px 20px 20px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0;
  border-radius: 20px;
  box-shadow: 7px 6px 8px 1px #e4e8f08a;
}
.ibox-footer {
  color: inherit;
  border-top: 1px solid #e7eaec;
  font-size: 90%;
  background: #ffffff;
  padding: 10px 15px;
}

.message-input {
    height: 90px !important;
}
.form-control, .single-line {
    background-color: #FFFFFF;
    background-image: none;
    border: 1px solid #e5e6e7;
    border-radius: 1px;
    color: inherit;
    display: block;
    padding: 6px 12px;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    width: 100%;
    font-size: 14px;
    border-radius: 20px;
}
.text-navy {
    color: #1ab394;
}
.mid-icon {
    font-size: 66px !important;
}
.m-b-sm {
    margin-bottom: 10px;
}


:root {
  --background-color: #ffffff;
  --color: #000000;
  --card-background-color: #1f1d2b;
  --card-color: white;
  --card-color--hover: black;
  --card-box-shadow: -1px 8px 14px -5px rgba(85, 85, 85, 0.88);
  --card-color-green: #a9e190;
  --card-color-red: #e79ca5;
  --card-color-yellow: #ffd791;
}

.dark {
  --background-color: #1f1d2b;
  --color: #ffffff;
  --card-background-color: white;
  --card-color: #1f1d2b;
  --card-color--hover: black;
  --card-box-shadow: -1px 8px 14px -5px rgba(85, 85, 85, 0.88);
  --card-color-green: #a9e190;
  --card-color-red: #e79ca5;
  --card-color-yellow: #ffd791;
}








.card-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 50px;
  padding: 50px 0px;
}
.card-wrapper .card {
  padding: 20px;
  color: var(--card-color--hover);
  text-align: center;
  transition: all 0.5s;
  cursor: pointer;
  border-radius: 20px;
}
.card-wrapper .card .card-icon-title-wrapper {
  position: relative;
  top: -20px;
}
.card-wrapper .card .card__icon {
  display: inline-block;
  padding: 30px;
  position: relative;
  top: -30px;
  border-radius: 50%;
  background-color: var(--background-color);
}
.card-wrapper .card .card__icon i {
  font-size: 64px;
}
.card-wrapper .card .card__title {
  padding: 0 10px 10px 0;
  font-size: 26px;
  font-weight: bold;
  transition: all 0.4s;
}
.card-wrapper .card .card__description {
  font-size: 20px;
  padding: 0 15px;
}

@media (min-width: 992px) {
 
  .card-wrapper {
    width: 50%;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: min(350px);
    gap: 20px;
  }
  .card-wrapper .card {
    position: relative;
    padding: 30px;
    background-color: #ffffff;
    color: var(--card-color);
  }
  .card-wrapper .card .card-icon-title-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 210px;
  }
  .card-wrapper .card .card__description {
    opacity: 0;
  }
  .card-wrapper .card .card__icon {
    background-color: transparent;
    top: 0px;
    padding-bottom: 10px;
    transform-origin: top center;
  }
  .card-wrapper .card .card__icon i {
    font-size: 7em;
  }
  .card-wrapper .card .card__title {
    padding: 0px;
    position: relative;
    top: 0px;
  }
  .card-wrapper .card:hover {
    transform: scale(1.04);
    color: var(--card-color--hover);
    box-shadow: var(--card-box-shadow);
  }
  .card-wrapper .card:hover .card-icon-title-wrapper {
    position: relative;
    top: -20px;
  }
  .card-wrapper .card:hover .card__description {
    position: absolute;
    bottom: 15px;
    padding: 0 30px 0 0;
    animation: fadeInDescription 0.5s 0.5s forwards;
  }
  .card-wrapper .card:hover .card__icon {
    top: -10px;
    padding: 30px;
    animation: resizeIcon 0.5s forwards, fadeInBg 1s forwards;
  }
  .card-wrapper .card:hover .card__title {
    animation: translateTitle 0.5s 0.2s forwards;
  }

  .card-discover:hover {
    background-color: var(--card-color-green);
  }

  .card-empathize:hover {
    background-color: var(--card-color-red);
  }

  .card-prototype:hover {
    background-color: var(--card-color-yellow);
  }
}
.card-discover {
  background-color: var(--card-color-green);
}

.card-empathize {
  background-color: var(--card-color-red);
}

.card-prototype {
  background-color: var(--card-color-yellow);
}

.icon__discover {
  color: var(--card-color-green);
}

.icon__empathize {
  color: var(--card-color-red);
}

.icon__prototype {
  color: var(--card-color-yellow);
}

@keyframes resizeIcon {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.65) translateY(-70px);
  }
}
@keyframes fadeInBg {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: var(--background-color);
  }
}
@keyframes translateTitle {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-100px);
  }
}
@keyframes fadeInDescription {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(-20px);
  }
}
@media (min-width: 992px) and (max-width: 1099px) {
  .card-wrapper {
    width: 100%;
  }
}
@media (min-width: 1100px) and (max-width: 1400px) {
  .card-wrapper {
    width: 75%;
  }
}
@media (min-width: 1401px) and (max-width: 1600px) {
  .card-wrapper {
    width: 100%;
  }
}

.card1 {
  margin-bottom: 24px;
  box-shadow: 0 2px 3px #e4e8f0;
 
}
.card1 {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #eff0f2;
  border-radius: 30px!important;
  padding: 25px;
}

/* Example CSS for the icons */
.lni-chevron-up::before {
  content: "\e600"; /* Assuming you're using a font icon library */
}

.lni-chevron-down::before {
  content: "\e601"; /* Assuming you're using a font icon library */
}

/* Optional: Add some custom styling */
.toggle-icon {
  font-size: 1.2rem;
  margin-left: 10px;
  transition: transform 0.3s ease;
  right: 35px;
  position: absolute;
  top: 35px;
}


.footernew{
  margin-top: 350px;
}

@media only screen and (max-width:786px) {
  .footernew{
    margin-top: 600px;
  }
  }

  #id-card {
    display: inline-block;
    float: left;
    margin-right: 2em;
  }
  

 

  .id-card {
    width: 400px;
    height: 250px;
    background: #ffffff;
    padding: 10px;
    border-radius: 20px;
    position: relative;
  }

  @media only screen and (max-width:786px){
    .id-card {
      width: 330px;
      height: 250px;
      background: #ffffff;
      padding: 10px;
      position: relative;
      border-radius: 20px;
      margin-left: 30px;
    }
  
  
  }

  .id-card__mugshot {
    position: absolute;
    right: 10px;
    width: 100px;
    height: 100px;
    border-left: 4px solid red;
    background: #ffffff;
    border-radius: 15px;
  }
  .id-card__mugshot img {
    width: 100px;
    height: 100px;
    border-radius: 15px;
  }
  .id-card__logo {
    width: 200px;
  }
  .id-card__subject-id {
    position: absolute;
    top: 54px;
    left: 130px;
    font-family: monospace;
    font-size: 14pt;
    transform: rotate(-4deg);
  }
  .id-card__banner {
    height: 10pt;
    background: red;
    margin-top: 10px;
    margin-left: -10px;
    padding-left: 12px;
  }
  .id-card__banner-text {
    color: #ffffff;
    font-size: 8pt;
    letter-spacing: 2px;
    line-height: 0;
    position: relative;
    top: -6px;
  }
  .id-card__details {
    padding-top: 20px;
    font-size: 10px;
    line-height: 3.5;
    text-transform: uppercase;
    width: 100%;
    display: inline-block;
    gap: 10px;
    display: grid;
  }
  .id-card__details--short {
    float: right;
    margin-left: 30px;
    width: 100px;
  }
  .id-card__detail {
    height: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  /* .id-card__detail + .id-card__detail {
    border-top: 1px solid #eeee;
  } */
  .id-card__detail-label {
    color: #333;
    font-weight: bold;
  }
  .id-card__detail-label:after {
    content: " • ";
    font-weight: normal;
  }
  
  .logo__big {
    font-size: 24pt;
    font-weight: bold;
    line-height: 1.3;
    letter-spacing: 4px;
  }
  .logo__small {
    font-size: 10pt;
    letter-spacing: 4px;
    position: relative;
    top: -10px;
    left: 2px;
  }
  
  .id-form__row {
    padding-bottom: 8px;
  }
  .id-form__row--inline {
    width: 10em;
    padding-right: 1em;
    padding-bottom: 1em;
    display: inline-block;
  }
  .id-form__label {
    font-size: 8pt;
    line-height: 8pt;
  }
  .material-ui-shadow {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  
  .material-ui-shadow:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }

  .ReactModal__Content {
    border: none !important;;
  }