.newlogin{
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 480px;
  margin-top: 130px;
  margin-bottom: 50px;
}

.newlogin p{
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  margin: 20px 0;
}

.newlogin span{
  font-size: 12px;
}

.newlogin a{
  color: #333;
  font-size: 13px;
  text-decoration: none;
  margin: 15px 0 10px;
}

.newlogin button{
  background-color: #06024f;
  color: #fff;
  font-size: 12px;
  padding: 10px 45px;
  border: 1px solid transparent;
  border-radius: 8px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-top: 10px;
  cursor: pointer;
}

.container button.hidden{
  background-color: transparent;
  border-color: #fff;
}

.newform{
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  height: 100%;
}

.newinput{
  background-color: #eee;
  border: none;
  margin: 8px 0;
  padding: 10px 15px;
  font-size: 13px;
  border-radius: 8px;
  width: 100%;
  outline: none;
}

.form-container{
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in{
  left: 0;
  width: 50%;
  z-index: 2;
}

.container.active .sign-in{
  transform: translateX(100%);
}

.sign-up{
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
  transition: all 0.5s;
}

.container.active .sign-up{
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: move 0.6s;
}

@keyframes move{
  0%, 49.99%{
      opacity: 0;
      z-index: 1;
  }
  50%, 100%{
      opacity: 1;
      z-index: 5;
  }
}

.social-icons{
  margin: 20px 0;
}

.social-icons a{
  border: 1px solid #ccc;
  border-radius: 20%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 3px;
  width: 40px;
  height: 40px;
  transition: all 0.5s;
}

.social-icons a:hover{
  scale: 1.3;
  border: 1px solid #000;
}

.toggle-container{
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: all 0.6s ease-in-out;
  border-radius: 150px 0 0 100px;
  z-index: 1000;
}

.container.active .toggle-container{
  transform: translateX(-100%);
  border-radius: 0 150px 100px 0;
}

.toggle{
  background-color: #512da8;
  height: 100%;
  background: linear-gradient(90deg, #06024f 0%, rgba(13,13,198,1) 27%, rgba(0,212,255,1) 100%);
  color: #fff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: all 0.6s ease-in-out;

}

.container.active .toggle{
  transform: translateX(50%);
}

.toggle-panel{
  position: absolute;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 30px;
  text-align: center;
  top: 0;
  transform: translateX(0);
  transition: all 0.6s ease-in-out;
}

.toggle-left{
  transform: translateX(-200%);
}

.container.active .toggle-left{
  transform: translateX(0);
}

.toggle-right{
  right: 0;
  transform: translateX(0);
}

.container.active .toggle-right{
  transform: translateX(200%);
}


.input-wrapper {
  position: relative;
  display: inline-block; /* Ensures the wrapper only takes as much width as necessary */
}

.country-code {
  position: absolute;
  left: 10px;  /* Adjust the left position according to your design */
  top: 50%;   /* Adjust the top position according to your design */
  transform: translateY(-50%);
  color: #000;  /* Adjust the color according to your design */
  pointer-events: none; /* Allows clicking through the span to the input */
}

.newinput {
  padding-left: 60px;  /* Adjust the padding-left to leave space for the country code */
}

#google-signin-button {
  background-color: #ffffff;
  color: #000000;
  border: none;
  padding: 10px 10px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  border: 1px solid gray;
}

#google-signin-button img {
  margin-right: 10px;
}
.small-input {
  width: 40px; /* Set the desired width */
  height: 40px; /* Set the desired height */
  font-size: 16px; /* Set the desired font size */
  text-align: center; /* Center text horizontally */
  margin: 0 5px; /* Add some margin to create a gap between the boxes */
  border: 1px solid #ddd; /* Add a border */
  border-radius: 5px; /* Set border radius for rounded corners */
  outline: none; /* Remove default focus outline */
}

.small-input:focus {
  border-color: #007bff; /* Change border color on focus if needed */
}