/* .bacimage{
   background: url(https://mensaindia.org/new/assets/img/site/homeaboutbg.png) center center;
   background-size: 100%;
   background-position: top center;
} */



.card {
   margin-bottom: 24px;
   box-shadow: 0 2px 3px #e4e8f0;
   height: 40vh;
}
.card {
   position: relative;
   display: flex;
   flex-direction: column;
   min-width: 0;
   word-wrap: break-word;
   background-color: #fff;
   background-clip: border-box;
   border: 1px solid #eff0f2;
   border-radius: 30px!important;
   height: 130px;
}
.avatar-md {
   height: 4rem;
   width: 4rem;
}
.rounded-circle {
   border-radius: 50%!important;
}
.img-thumbnail {
   padding: 0.25rem;
   background-color: #f1f3f7;
   border: 1px solid #eff0f2;
   border-radius: 0.75rem;
}
.avatar-title {
   align-items: center;
   background-color: #3b76e1;
   color: #fff;
   display: flex;
   font-weight: 500;
   height: 100%;
   justify-content: center;
   width: 100%;
}
.bg-soft-primary {
   background-color: rgba(59,118,225,.25)!important;
}
a {
   text-decoration: none!important;
}
.badge-soft-danger {
   color: #f56e6e !important;
   background-color: rgba(245,110,110,.1);
}
.badge-soft-success {
   color: #63ad6f !important;
   background-color: rgba(99,173,111,.1);
}
.mb-0 {
   margin-bottom: 0!important;
}
.badge {
   display: inline-block;
   padding: 0.25em 0.6em;
   font-size: 75%;
   font-weight: 500;
   line-height: 1;
   color: #fff;
   text-align: center;
   white-space: nowrap;
   vertical-align: baseline;
   border-radius: 0.75rem;
}