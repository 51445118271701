.event .title {
  margin: 0px 0 0;
}

.event .nav-content {
  float: right;
  width: 100%;
}

.event .btn {
  background: #ff007a;
}

.event .nav-content strong {
  display: block;
  font-size: 18px;
  color: #002691;
}

.event nav>div a.nav-item.nav-link.active .event .spicel-features {
  background: #fafaff;
}

.event .nav-fill .nav-item {
  
  text-transform: uppercase;
  margin: 0 5px;
  font-size: 12px;
}

.event .nav-fill .nav-item text-t i {
  margin-right: 10px;
}

.event .nav-fill .nav-item i::before {
  font-size: 26px;
  margin-right: 13px;
  position: relative;
  top: 4px;
}

.event nav>.nav.nav-tabs {
  border: none;
  color: #fff;
  border-radius: 0;
  flex-wrap: inherit;
 
}

.event .nav-item {
  margin: 0 0;
}

.event a.nav-item.nav-link.active {
  background-color:#000C80 !important;
  color: #fff !important;
}

.event a.nav-item.nav-link.active strong {
  color: #fff;
}

.event nav>div a.nav-item.nav-link,
.event nav>div a.nav-item.nav-link.active {
  border: none;
  padding: 18px 25px;
  color: #47759d;
  border-radius: 10px 10px 10px 30px;
  background: #fff;
  position: relative;
  background: #f5faff;
}

/* .event nav>div a.nav-item.nav-link.active:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 15px 15px 0;
  border-color: transparent #02aff4 transparent transparent;
  position: absolute;
  left: 0;
  bottom: -15px;    
  content: '';
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  border-color:transparent #02aff4 transparent transparent;
} */

.event .tab-content {
  padding: 50px 25px 0 !important;
}

.event .user h5 {
  margin-top: 20px;
}

.event .user p {
  font-weight: bold;
  color: #ff007a;
}

.event .user ul {
  padding-top: 10px;
  border-top: 1px solid #edf5ff;
}

.event .user ul li {
  margin-top: 10px;
}

.event .user ul li i {
  margin-right: 5px;
}

.event-list-content {
  
  padding: 50px;
  position: relative;
  border: 10px solid #edf5ff;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width:475px){
  .event nav>.nav.nav-tabs{
    flex-wrap: wrap;
    gap: 20px;
  }

}


 


.event-list-content:hover {
  border: 10px solid #ff007a;
}

.event-list-content .crical {
  position: absolute;
  background: #ff007a;
  right: -66px;
  bottom: -73px;
  font-size: 30px;
  color: #fff;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  padding: 50px;
}

.event-list-content h2 {
  margin-bottom: 30px;
}

.event-list-content .btn {
  background: none;
  border: 2px solid #edf5ff;
  color: #47759d;
  font-weight: bold;
  font-size: 14px;
  margin-right: 10px;
}

.event-list-content .btn:hover {
  background: #ff007a;
  border: 2px solid #ff007a;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  margin-right: 10px;
  box-shadow: 0px 10px 19px 0px #ff007a66 !important;
}

.event-list-content ul li {
  display: inline-block;
  font-size: 16px;
  margin-right: 20px;
  margin-bottom: 20px;
  color: #ff007a;
}

.event-list-content ul li i {
  margin-right: 5px;
}

.event nav>div a.nav-item.nav-link .lgt-icon {
  display: none;
}

.event nav>div a.nav-item.nav-link.active .lgt-icon {
  display: inline-block;
}

.event nav>div a.nav-item.nav-link.active .drk-icon {
  display: none;
}

.event nav>div a.nav-item.nav-link.active .drk-icon {
  display: none;
}

.img,
img {
  /* max-width: 100%; */
  transition: all 0.3s ease-out 0s;
}

.event .user h5 {
  margin-top: 20px;
}

a,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  overflow-wrap: break-word;
}

h5 {
  font-size: 18px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
  color: #002691;
  margin-top: 0px;
  font-style: normal;
  /* font-weight: bold; */
  text-transform: normal;
  font-size: 20px;
}

.event .user p {
  font-weight: bold;
  color: #ff007a;
}

.event .user ul {
  padding-top: 10px;
  border-top: 1px solid #edf5ff;
}

ul {
  margin: 0px;
  padding: 0px;
}

.event-list-content .crical {
  position: absolute;
  background: #ff007a;
  right: -66px;
  bottom: -73px;
  font-size: 30px;
  color: #fff;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  padding: 50px;
}

.fix {
  overflow: hidden;
}

/* .event nav>div a.nav-item.nav-link.active:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 15px 15px 0;
  border-color: transparent #e7015e transparent transparent;
  position: absolute;
  left: 0;
  bottom: -15px;
  content: '';
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  border-color: transparent #ff007a transparent transparent;
} */

.main-timeline3 {
  position: relative
}

.main-timeline3 .timeline {
  position: relative;
  margin-top: -69px
}

.main-timeline3 .timeline:first-child {
  margin-top: 0
}

.main-timeline3 .timeline-icon,
.main-timeline3 .year {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0
}

.main-timeline3 .timeline:after,
.main-timeline3 .timeline:before {
  content: "";
  display: block;
  width: 100%;
  clear: both
}

.main-timeline3 .timeline:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2
}

.main-timeline3 .timeline-icon {
  width: 210px;
  height: 210px;
  border-radius: 50%;
  border: 25px solid transparent;
  border-top-color: #f44556;
  border-right-color: #f44556;
  z-index: 1;
  transform: rotate(45deg)
}

.main-timeline3 .year {
  display: block;
  width: 110px;
  height: 110px;
  line-height: 110px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, .4);
  font-size: 30px;
  font-weight: 700;
  color: #f44556;
  text-align: center;
  transform: rotate(-45deg)
}

.main-timeline3 .timeline-content {
  width: 35%;
  float: right;
  background: #f44556;
  padding: 30px 20px;
  margin: 50px 0;
  box-shadow: 0 10px 25px -10px rgba(72, 29, 99, .3);
  z-index: 1;
  position: relative;
  border-radius: 20px;
}

.main-timeline3 .timeline-content:before {
  content: "";
  width: 20%;
  height: 15px;
  background: #f44556;
  position: absolute;
  top: 50%;
  left: -20%;
  z-index: -1;
  transform: translateY(-50%)
}

.main-timeline3 .title {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  margin: 0 0 10px
}

.main-timeline3 .description {
  font-size: 16px;
  color: #fff;
  line-height: 24px;
  margin: 0
}

.main-timeline3 .timeline:nth-child(2n) .timeline-icon {
  transform: rotate(-135deg);
  border-top-color: #e97e2e;
  border-right-color: #e97e2e
}

.main-timeline3 .timeline:nth-child(2n) .year {
  transform: rotate(135deg);
  color: #e97e2e
}

.main-timeline3 .timeline:nth-child(2n) .timeline-content {
  float: left
}

.main-timeline3 .timeline:nth-child(2n) .timeline-content:before {
  left: auto;
  right: -20%
}

.main-timeline3 .timeline:nth-child(2n) .timeline-content,
.main-timeline3 .timeline:nth-child(2n) .timeline-content:before {
  background: #e97e2e
}

.main-timeline3 .timeline:nth-child(3n) .timeline-icon {
  border-top-color: #13afae;
  border-right-color: #13afae
}

.main-timeline3 .timeline:nth-child(3n) .year {
  color: #13afae
}

.main-timeline3 .timeline:nth-child(3n) .timeline-content,
.main-timeline3 .timeline:nth-child(3n) .timeline-content:before {
  background: #13afae
}

.main-timeline3 .timeline:nth-child(4n) .timeline-icon {
  border-top-color: #105572;
  border-right-color: #105572
}

.main-timeline3 .timeline:nth-child(4n) .year {
  color: #105572
}

.main-timeline3 .timeline:nth-child(4n) .timeline-content,
.main-timeline3 .timeline:nth-child(4n) .timeline-content:before {
  background: #105572
}

@media only screen and (max-width:1199px) {
  .main-timeline3 .timeline {
      margin-top: -103px
  }

  .main-timeline3 .timeline-content:before {
      left: -16%
  }

  .main-timeline3 .timeline:nth-child(2n) .timeline-content:before {
      right: -16%
  }
}

@media only screen and (max-width:990px) {
  .main-timeline3 .timeline {
      margin-top: -127px
  }

  .main-timeline3 .timeline-content:before {
      left: -2%
  }

  .main-timeline3 .timeline:nth-child(2n) .timeline-content:before {
      right: -2%
  }
}

@media only screen and (max-width:975px) {
  .main-timeline3 .timeline {
      margin-top: 0;
      overflow: hidden
  }

  .main-timeline3 .timeline:before,
  .main-timeline3 .timeline:nth-child(2n):before {
      box-shadow: none
  }

  .main-timeline3 .timeline-icon,
  .main-timeline3 .timeline:nth-child(2n) .timeline-icon {
      margin-top: -30px;
      margin-bottom: 20px;
      position: relative;
      transform: rotate(135deg)
  }

  .main-timeline3 .timeline:nth-child(2n) .year,
  .main-timeline3 .year {
      transform: rotate(-135deg)
  }

  .main-timeline3 .timeline-content,
  .main-timeline3 .timeline:nth-child(2n) .timeline-content {
      width: 100%;
      float: none;
      border-radius: 0 0 20px 20px;
      text-align: center;
      padding: 25px 20px;
      margin: 0 auto
  }

  .main-timeline3 .timeline-content:before,
  .main-timeline3 .timeline:nth-child(2n) .timeline-content:before {
      width: 15px;
      height: 25px;
      position: absolute;
      top: -22px;
      left: 50%;
      z-index: -1;
      transform: translate(-50%, 0)
  }
}


@media only screen and (min-width: 975px) and (max-width: 1400px) {
  .mgt1 {
    margin-top: -103px!important;
  }
  .mgt2 {
    margin-top: -152px!important;
  }

  .mgt3 {
    margin-top: -175px!important;
  }
  .mgt4 {
    margin-top: -201px!important;
  }
  .mgt5 {
    margin-top: -188px!important;
  }
  .mgt6 {
    margin-top: -156px!important;
  }
  .mgt7 {
    margin-top: -142px!important;
  }
  .mgt8 {
    margin-top: -104px!important;
  }

  .mgt9{
    margin-top: -128px!important;
  }
  .mgt10 {
    margin-top: -201px!important;
  }
  .mgt11 {
    margin-top: -200px!important;
  }
 
}

@media only screen and (min-width: 1400px) and (max-width: 1700px) {
  .mgt1 {
    margin-top: -68px!important;
  }
  .mgt2 {
    margin-top: -93px!important;
  }

  .mgt3 {
    margin-top: -115px!important;
  }
  .mgt4 {
    margin-top: -129px!important;
  }
  .mgt5 {
    margin-top: -115px!important;
  }
  .mgt6 {
    margin-top: -93px!important;
  }
  .mgt7 {
    margin-top: -91px!important;
  }
  .mgt8 {
    margin-top: -69px!important;
  }

  .mgt9{
    margin-top: -79px!important;
  }
  .mgt10 {
    margin-top: -129px!important;
  }
  .mgt11 {
    margin-top: -127px!important;
  }
 
}



