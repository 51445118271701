


.card{
  box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
}

.avatar-md {
  height: 5rem;
  width: 5rem;
}

.fs-19 {
  font-size: 19px;
}

.primary-link {
  color: #314047;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

a {
  color: #02af74;
  text-decoration: none;
}

.bookmark-post .favorite-icon a,
.job-box.bookmark-post .favorite-icon a {
  background-color: #da3746;
  color: #fff;
  border-color: danger;
}

.favorite-icon a {
  display: inline-block;
  width: 30px;
  height: 30px;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #eff0f2;
  border-radius: 6px;
  color: rgba(173, 181, 189, .55);
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}


.candidate-list-box .favorite-icon {
  position: absolute;
  right: 22px;
  top: 22px;
}

.fs-14 {
  font-size: 14px;
}

.bg-soft-secondary {
  background-color: rgba(116, 120, 141, .15) !important;
  color: #74788d !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: none;
}

.page-link{

  border-radius: 50%;
  width: 35px;
  height: 35px;
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width)* 10);
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: none;
}

.page-link{

  border-radius: 50%;
  width: 35px;
  height: 35px;
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width)* 10);
}



.education {
  --bg-color: #ffd861;
  --bg-color-light: #ffeeba;
  --text-color-hover: #4C5656;
  --box-shadow-color: rgba(255, 215, 97, 0.48);
}

.credentialing {
  --bg-color: #B8F9D3;
  --bg-color-light: #e2fced;
  --text-color-hover: #4C5656;
  --box-shadow-color: rgba(184, 249, 211, 0.48);
}

.wallet {
  --bg-color: #CEB2FC;
  --bg-color-light: #F0E7FF;
  --text-color-hover: #fff;
  --box-shadow-color: rgba(206, 178, 252, 0.48);
}

.human-resources {
  --bg-color: #DCE9FF;
  --bg-color-light: #f1f7ff;
  --text-color-hover: #4C5656;
  --box-shadow-color: rgba(220, 233, 255, 0.48);
}

.cardss {
  width: 220px;
  height: 220px;
  background: #fff;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 14px 26px rgba(0,0,0,0.04)!important;
  transition: all 0.3s ease-out;
  text-decoration: none;
  
}

.cardss:hover {
  transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: 0 24px 36px rgba(0,0,0,0.11),
    0 24px 46px var(--box-shadow-color)!important;
}

.cardss:hover .overlay {
  transform: scale(4) translateZ(0);
}

.cardss:hover .circle {
  border-color: var(--bg-color-light);
  background: var(--bg-color);
}

.cardss:hover .circle:after {
  background: var(--bg-color-light);
}

.cardss:hover p {
  color:white;
}

.cardss:active {
  transform: scale(1) translateZ(0);
  box-shadow: 0 15px 24px rgba(0,0,0,0.11),
    0 15px 24px var(--box-shadow-color);
}

.cardss p {
  font-size: 17px;
  color: #4C5656;
  margin-top: 30px;
  z-index: 1000;
  transition: color 0.3s ease-out;
}

.circle {
  width: 131px;
  height: 131px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid var(--bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-out;
  top: 35px;  
}

.circle:after {
  content: "";
  width: 118px;
  height: 118px;
  display: block;
  position: absolute;
  background: var(--bg-color);
  border-radius: 50%;
  top: 7px;
  left: 7px;
  transition: opacity 0.3s ease-out;
}

.circle svg {
  z-index: 10000;
  transform: translateZ(0);
}

.overlay {
  width: 118px;
  position: absolute; 
  height: 118px;
  border-radius: 50%;
  background: #0056b3;
  top: 50px;
  left: 50px;
  z-index: 0;
  transition: transform 0.3s ease-out;
}